import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import type { CreateAccountPagePropsType } from "../components/CreateAccount/CreateAccountAssets"
import { formatPrismicCountries } from "../utils/utilities"
import PublicRoute from "../routes/PublicRoute"

import CreateResellerAccount from "../components/CreateResellerAccount"
import { sessionReceiverURL } from "../../../gatsby-theme-common/src/services/amplifyStorage"

const CreateV2ResellerAccountPage = ({
  location,
  pageContext,
  data,
}: CreateAccountPagePropsType) => {
  const lang = pageContext.unPublishedLang || pageContext.lang
  const {
    prismicLayout = {},
    prismicCreateResellerAccountPage = {},
    prismicUpdatePaymentDetailsPage = {},
  } = data || {}
  if (!data) {
    return null
  }

  // groom data of country names and codes
  const countriesData = prismicUpdatePaymentDetailsPage.data?.body[0]?.items
  const countries = countriesData ? formatPrismicCountries(countriesData) : []

  // groom data of states names and codes
  const statesData = prismicLayout.data?.body3 || []
  const statesOfCountries = statesData.reduce((result, countryData) => {
    const countryCode = countryData.primary.country_code.text
    if (countryCode) {
      // only display US and CA states for now
      if (countryCode !== "US" && countryCode !== "CA") return result
      result[countryCode] = countryData.items.map(stateData => ({
        name: stateData.state_code.text + " - " + stateData.state_name.text,
        value: stateData.state_code.text,
      }))
    }
    return result
  }, {})

  return (
    <>
      <PublicRoute
        component={() =>
          CreateResellerAccount({
            data: prismicCreateResellerAccountPage.data,
            countries,
            statesOfCountries,
            layoutData: prismicLayout.data,
            lang,
            location,
            version: 2,
          })
        }
        lang={lang}
        location={location}
      />
      {process.env.GATSBY_RT_26_07_2022_SYNC_SESSION === "true" &&
        sessionReceiverURL && (
          <iframe
            title="session_receiver"
            id="session_receiver"
            src={sessionReceiverURL}
            width="0"
            height="0"
          />
        )}
    </>
  )
}

export const query = graphql`
  query($lang: String, $uid: String) {
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutCreateAccountPage
    }
    prismicCreateResellerAccountPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      ...CreateResellerAccountPageFragment
    }
    prismicUpdatePaymentDetailsPage(lang: { eq: $lang }) {
      ...UpdatePaymentDetailsPage
    }
  }
`
export default withPreview(CreateV2ResellerAccountPage)
